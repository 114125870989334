/* ---------------------------------------------------------- */
/*
/* wCMS - base.css
/*
/* klasy podstawowych modyfikacji styli
/* (nie modyfikować, zmiany nadpisywać w layout.css)
/*
/* ---------------------------------------------------------- */

// Zmienne
$font-family: 'Oswald', 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
$font-content: 'Roboto';
$font-size: 18px;
$color-default: #181717;
$color-primary: #00a2ff;

*, *:before, *:after {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}

html, body {width: 100%; height: auto; overflow: auto}
html.no-scroll {overflow-y: hidden !important}
body * {margin: 0; padding: 0; text-overflow: ellipsis; list-style: none}

#page {min-height: 100%; position: relative; overflow: hidden}
.container {margin-left: auto; margin-right: auto}

a {text-decoration: none}
p {margin: 0}
ul {list-style-type: none; margin: 0; padding: 0}
address {font-style: normal}
figure img {display: block; width: 100%}


/*----- TEKST -----*/
.text-left      {text-align: left}
.text-center    {text-align: center}
.text-right     {text-align: right}
.text-bold      {font-weight: bold}
.text-italic    {font-style: italic}
.text-strike    {text-decoration: line-through}
.text-underline {text-decoration: underline}
.text-uppercase {text-transform: uppercase}
.text-lowercase {text-transform: lowercase}
.text-hide      {text-indent: -999em}
.text-nowrap {white-space: nowrap}

.text-color {color: $color-primary}

.font-content {
    font-family: $font-content;
    font-size: 22px;
    line-height: 1.5em;
    @include gridle_state(md sm xs) {
        font-size: 16px;
    }
}

/*----- TABELE -----*/
.table-wrap {width: 100%; overflow-x: auto}


/*----- POZYCJONOWANIE -----*/
.center {margin-left: auto; margin-right: auto}
.fclear {float: none; clear: both}
.fleft  {float: left}
.fright {float: right}

.crop {position: relative; overflow: hidden}
.hide {display: none}
.invisible {opacity: 0}


/*----- OBRAMOWANIE -----*/
.border        {border: 1px solid #c1c1c1}
.border-top    {border-top: 1px solid #c1c1c1}
.border-left   {border-left: 1px solid #c1c1c1}
.border-right  {border-right: 1px solid #c1c1c1}
.border-bottom {border-bottom: 1px solid #c1c1c1}


/*----- MARGINESY ------*/
.mTop5      {margin-top: 5px}
.mTop10     {margin-top: 10px}
.mTop20     {margin-top: 20px}
.mTop30     {margin-top: 30px}
.mTop40     {margin-top: 40px}
.mTop50     {margin-top: 50px}
.mRight5    {margin-right: 5px}
.mRight10   {margin-right: 10px}
.mRight20   {margin-right: 20px}
.mRight30   {margin-right: 30px}
.mRight40   {margin-right: 40px}
.mRight50   {margin-right: 50px}
.mBottom5   {margin-bottom: 5px}
.mBottom10  {margin-bottom: 10px}
.mBottom15  {margin-bottom: 15px}
.mBottom20  {margin-bottom: 20px}
.mBottom30  {margin-bottom: 30px}
.mBottom40  {margin-bottom: 40px}
.mBottom50  {margin-bottom: 50px}
.mLeft5     {margin-left: 5px}
.mLeft10    {margin-left: 10px}
.mLeft20    {margin-left: 20px}
.mLeft30    {margin-left: 30px}
.mLeft40    {margin-left: 40px}
.mLeft50    {margin-left: 50px}
   
.pTop5      {padding-top: 5px}
.pTop10     {padding-top: 10px}
.pTop20     {padding-top: 20px}
.pTop30     {padding-top: 30px}
.pTop40     {padding-top: 40px}
.pTop50     {padding-top: 50px}
.pRight5    {padding-right: 5px}
.pRight10   {padding-right: 10px}
.pRight20   {padding-right: 20px}
.pRight30   {padding-right: 30px}
.pRight40   {padding-right: 40px}
.pRight50   {padding-right: 50px}
.pBottom5   {padding-bottom: 5px}
.pBottom10  {padding-bottom: 10px}
.pBottom20  {padding-bottom: 20px}
.pBottom30  {padding-bottom: 30px}
.pBottom40  {padding-bottom: 40px}
.pBottom50  {padding-bottom: 50px}
.pLeft5     {padding-left: 5px}
.pLeft10    {padding-left: 10px}
.pLeft20    {padding-left: 20px}
.pLeft30    {padding-left: 30px}
.pLeft40    {padding-left: 40px}
.pLeft50    {padding-left: 50px}


/*----- DROPDOWN ----- */
.dropdown {position: absolute; z-index: 100; display: none}
.dropdown .dropdown-menu,
.dropdown .dropdown-panel {overflow: visible; min-width: 160px; max-width: 360px; margin: 0; padding: 4px 0; background: #FFF; border: solid 1px #DDD; border: solid 1px rgba(0, 0, 0, .2); border-radius: 6px; box-shadow: 0 5px 10px rgba(0, 0, 0, .2); text-align: left; list-style: none}
.dropdown .dropdown-panel {color: #555; padding: 10px}
.dropdown .dropdown-panel .dropdown-menu {margin: 0 -10px; padding: 0; border: 0; box-shadow: none}
.dropdown.dropdown-tip {margin-top: 8px}
.dropdown.dropdown-tip:before {content: '';  display: inline-block;  position: absolute;  top: -6px;  left: 9px;  border-left: 7px solid transparent;  border-right: 7px solid transparent;  border-bottom: 7px solid #CCC;  border-bottom-color: rgba(0, 0, 0, 0.2)}
.dropdown.dropdown-tip.dropdown-anchor-center:before {left: calc(50% - 5px)}
.dropdown.dropdown-tip.dropdown-anchor-right:before {left: auto; right: 9px}
.dropdown.dropdown-tip:after {content: '';  display: inline-block;  position: absolute;  top: -5px;  left: 10px;  border-left: 6px solid transparent;  border-right: 6px solid transparent;  border-bottom: 6px solid #FFF}
.dropdown.dropdown-tip.dropdown-anchor-center:after {left: calc(50% - 4px)}
.dropdown.dropdown-tip.dropdown-anchor-right:after {left: auto; right: 10px}
.dropdown.dropdown-scroll .dropdown-menu,
.dropdown.dropdown-scroll .dropdown-panel {overflow: auto; max-height: 358px}
.dropdown .dropdown-menu li {list-style: none; padding: 0 0; margin: 0; line-height: 18px}
.dropdown .dropdown-menu li > a,
.dropdown .dropdown-menu label {display: block; margin: 0; padding: 3px 15px; color: #555; line-height: 18px; text-decoration: none; white-space: nowrap}
.dropdown .dropdown-menu li > a:hover,
.dropdown .dropdown-menu label:hover {background-color: #08C; color: #FFF; cursor: pointer}
.dropdown .dropdown-menu .dropdown-divider {margin: 5px 0; padding: 0; border-top: solid 1px #E5E5E5; font-size: 1px}
.dropdown.has-icons li > a {padding-left: 30px; background-position: 8px center; background-repeat: no-repeat}


/*----- SORT -----*/
.sortable .order-trigger {cursor: move !important}
.sortable li.dragged {position: absolute; top: 0; z-index: 100; min-height: 43px; border: 1px solid #c1c1c1}
/*.sortable li.placeholder {position: relative; border: 1px dashed #c1c1c1}
.sortable li.placeholder:before {position: absolute}*/
.sortable li.placeholder {position: relative; margin: 0; padding: 0; border: 1px dashed #c1c1c1}


/*----- ANIMACJE -----*/
.fade-in-up   {-webkit-animation: fade-in-up 1s; -webkit-animation-fill-mode: both; animation: fade-in-up 1s; animation-fill-mode: both}

@-webkit-keyframes fade-in-up {
    0% {opacity: 0; -webkit-transform: translateY(-20px)}
    100% {opacity: 1;-webkit-transform: translateY(0)}
}
@keyframes fade-in-up {
    0% {opacity: 0; transform: translateY(-20px)}
    100% {opacity: 1; transform: translateY(0)}
}
.fade-out   {-webkit-animation: fade-out .25s; -webkit-animation-fill-mode: both; animation: fade-out .25s; animation-fill-mode: both}

@-webkit-keyframes fade-out {
    0% {opacity: 1}
    100% {opacity: 0}
}
@keyframes fade-out {
    0% {opacity: 1}
    100% {opacity: 0}
}