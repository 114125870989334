.page-footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 54px;
    padding: 9px 0;
    background: #282725;
    color: #fff;
    font-family: $font-content;
    font-size: 12px;
    text-transform: uppercase;
    .container {
        @include gridle_container();
        margin: 0 auto;
        img {
            margin-top: 5px;
        }
    }
    a {
        color: #fff;
    }
    .copyright {
        @include gridle_grid(7);
    }
    .credits {
        @include gridle_grid(5);
        text-align: right;
        img {
            position: relative;
            top: -3px;
            display: inline-block;
            height: 12px;
            margin-left: 8px;
            vertical-align: top;
        }
    }
    @include gridle_state(md sm xs) {
        & {
            height: auto;
        }
    }
    @include gridle_state(sm xs) {
        .copyright,
        .credits {
            @include gridle_grid(12);
            text-align: center;
        }
    }
}