/*----- BASE -----*/

body {
    background: #fff; 
    font-family: $font-family;
    color: $color-default;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    @media all and (min-width: 1921px) {
        & {
            background-size: cover;
        }
    }
}

.page-container {
    position: relative;
    margin: 40px;
    @include gridle_state (md sm xs) {
        margin: 0;
    }
}

body, .grid > * {
    font-size: $font-size;
}

::selection {background: #b3d4fc; text-shadow: none}
::-moz-selection {background: #b3d4fc; text-shadow: none}

::-webkit-input-placeholder {color: #fff}
::-moz-placeholder {color: #fff}
:-ms-input-placeholder {color: #fff}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    letter-spacing: -2.5px;
}

a {
    color: $color-primary; 
    transition: all 0.25s ease;
    &:hover {
        color: #666; 
        transition: all 0.25s ease;
    }
}

.container {
    width: 100%;
    max-width: 1300px;
    padding: 0 10px;
    margin: 0 auto;
}

blockquote {
    position: relative;
    padding: 20px 20px 20px 60px;
    border: 1px solid #E8E2DA;
    border-width: 1px 0;
    font-style: italic;
    &:after {
        content: open-quote;
        position: absolute;
        top: 16px;
        left: 0;
        color: #E8E2DA;
        font-size: 72px;
        font-weight: 700;
        line-height: 1;
    }
}


/*----- MARGINESY ------*/
.margin     {margin: 20px}
.wMargin    {margin: 0 20px}
.hMargin    {margin: 20px 0}

.padding    {padding: 20px}


/*----- TABELE -----*/
table {
    width: 100%;
    border-spacing: 10px;
    color: #222;
    tr {
        & > * {
            padding: 15px 30px; 
            background: #F0ECE7; 
            text-align: left;
        }
        &:nth-child(2n+1)>* {
            background: #E8E2DA;
        }
        * > * {
            border-left: 1px solid #fff;
        }
        & + tr > * {
            border-top: 1px solid #fff;
        }
        th {
            background: #00A2FF !important;
            color: #fff;
            font-size: 18px;
            text-transform: uppercase;
        }
    }
    thead th {
        text-align: center;
    }
}
