.page-index {
    .slider {
        overflow: hidden;
        margin-bottom: 70px;
        max-height: 720px;
        .slide {
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: -45px;
            left: -10px;
            width: 110%;
            height: 100px;
            background: #fff;
            transform: rotate(-3deg);
        }
        .description {
            position: absolute;
            top: 30%;
            left: 105px;
            text-transform: uppercase;
            color: #fff;
            font-weight: 300;
            font-size: 70px;
            font-size: 5.5vw;
            line-height: 5.5vw;
            text-align: left;
            header {
                font-weight: 700;
            }
            .small {
                font-size: 24px;
                line-height: 1.4em;
            }
        }
    }
    section {
        header {
            text-transform: uppercase;
            font-size: 36px;
            line-height: 1.7em;
            text-align: left;
            
            @include gridle_state(md sm xs) {
                font-size: 22px;
                line-height: 42px;
                text-align: center;
                &:after {
                    margin: 0 auto;
                }
            }
        }
    }
    .section-column {
        @include gridle_container();
        margin-bottom: 100px;
        figure {
            @include gridle_grid(5);
            img {
                max-width: 485px;
                margin: 0 auto;
            }
        }
        .content {
            @include gridle_grid(7);
            text-align: center;
        }
        @include gridle_state(md sm xs) {
            figure, .content {
                @include gridle_grid(12);
            }
        }
    }
    .section-center {
        &, header {
            text-align: center;
        }
        .button {
            margin-top: 22px;
            padding-left: 72px;
            padding-right: 50px;
            &:before {
                position: absolute;
                content: '\e900';
                font-size: 8px;
                margin-right: 10px;
                margin-left: -30px;
                font-family: 'slick';
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
    .section-list {
        @include gridle_container();
        max-width: 1080px;
        margin: 0 auto;
        margin-bottom: 50px;
        padding: 70px 0;
        text-transform: uppercase;
        .column {
            @include gridle_grid(4);
            position: relative;
            text-align: center;
            & + .column {
                ul:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -12px;
                    width: 27px;
                    height: 27px;
                    background: url('../img/img_plus.png') no-repeat;
                    transform: translateY(-50%);
                }
            }
        }
        ul {
            display: inline-block;
            text-align: left;
            li + li {
                margin-top: 15px;
            }
        }
        @include gridle_state(md sm xs) {
            .column {
                @include gridle_grid(12);
                padding-top: 5px;
                ul {
                    text-align: center;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .chains {
        &:after {
            content: '';
            display: block;
            width: 103px;
            height: 20px;
            background: url('../img/img_bike_chain.png') no-repeat;
            margin-bottom: 20px;
        }
    }
    .section-slogan {
        @include gridle_container();
        figure {
            @include gridle_grid(8);
            @include gridle_no_gutter();
            position: relative;
            figcaption {
                position: absolute;
                right: 0;
                top: 50%;
                width: 520px;
                margin-right: -300px;
                padding: 30px 40px;
                background: #fff;
                font-size: 50px;
                transform: translateY(-50%);
            }
        }
        @include gridle_state(md sm xs) {
            position: relative;
            min-height: 300px;
            margin-left: -10px;
            width: calc(100% + 20px);
            figure {
                @include gridle_grid(12);
                figcaption {
                    width: auto;
                    min-width: 300px;
                    left: 50%;
                    right: auto;
                    margin-right: 0;
                    font-size: 32px;
                    transform: translate(-50%, -50%) !important;
                }
            }
        }
        @include gridle_state(sm xs) {
            height: 300px;
            figure {
                height: 100%;
                img {
                    width: auto;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
                figcaption {
                    font-size: 22px;
                }
            }
        }
    }
    .section-partners {
        margin: 100px 0;
        padding: 10px;
        img {
            max-width: 180px;
            margin: 0 auto;
        }
        .slick-slider {
            margin-bottom: 0;
        }
        @include gridle_state(sm xs) {
            .slick-prev {
                left: 0;
            }
            .slick-next {
                right: 0;
            }
        }
    }
    .section-contact {
        background: url('../img/bg_contact.png');
        .page-container {
            @include gridle_container();
            margin-right: 0;
            margin-bottom: 0;
        }
        h3 {
            position: relative;
            margin: 20px 0;
            font-size: 40px;
            line-height: 1em;
            text-transform: uppercase;
            &:before {
                content: '';
                position: absolute;
                top: 10px;
                left: -36px;
                display: inline-block;
                width: 25px;
                height: 19px;
                background: url('../img/img_chain.png') no-repeat;
            }
        }
        .content {
            @include gridle_grid(5)
            height: 500px;
            padding-top: 48px;
            text-transform: uppercase;
        }
        #map {
            position: absolute !important;
            top: 0;
            right: 0;
            height: 500px;
            padding: 0 !important;
            @include gridle_grid(7)
        }
        .hours {
            display: inline-block;
            padding: 2px 10px;
            color: #fff;
            background: #000000;
            margin-top: 5px;
            font-family: 'Roboto';
        }
        @include gridle_state(md sm xs) {
            .container {
                padding: 0;
            }
            h3 {
                margin-top: 0;
            }
            h3:before {
                position: static;
                display: block;
                margin: 0 auto 20px;
            }
            .content {
                @include gridle_grid(12);
                height: auto;
                padding: 30px 10px;
                text-align: center;
            }
            #map {
                position: relative !important;
                width: 100%;
            }
        }
        @include gridle_state(sm xs) {
            #map {
                height: 300px;
            }
        }
    }
    @include gridle_state(md sm xs) {
        .slider {
            height: 500px;
            & > div,
            .slick-track,
            .slider,
            figure {
                height: 100%;
            }
            figure {
                position: relative;
                overflow: hidden;
            }
            img {
                position: absolute;
                left: 50%;
                width: auto;
                height: 100%;
                transform: translateX(-50%);
            }
            .description {
                z-index: 2;
                left: 50%;
                text-align: center;
                transform: translateX(-50%);
                .small {
                    font-size: 16px;
                }
            }
        }
    }
    @include gridle_state(sm xs) {
        .slider {
            margin-bottom: -20px;
            height: 350px;
            .description {
                line-height: 7vw;
            }
        }
        .section-column {
            margin-bottom: 40px;
        }
        .section-list {
            margin-bottom: 0;
        }
        .section-partners {
            margin: 50px auto;
        }
    }
}
.remodal .wysiwyg {
    text-align: left;
}

.scroll-top {
    display: none;
    position: fixed;
    right: 0;
    top: 70%;
    width: 56px;
    height: 112px;
    padding-top: 40px;
    color: #fff;
    border: none;
    background: url('../img/scroll-up.png') 50% 25px no-repeat #00a2ff;
    text-transform: uppercase;
    @include gridle_state(sm xs) {
        & {
            display: none !important;
        }
    }
}