.page-price-list {
    .header-img {
        position: relative;
        width: 100%;
        height: 378px;
        margin-bottom: 60px;
        background: url('../img/img_header_price.jpg') no-repeat;
        background-position: center;
        background-size: cover;
        padding: 150px 0 50px;
        color: #fff;
        font-weight: 300;
        font-size: 2vw;
        line-height: 2vw;
        text-align: center;
        text-transform: uppercase;
    }
    .page-header {
        .desktop-menu {
            li:not(.back) {
                display: none;
            }
        }
    }
    table {
        max-width: 980px;
        margin: 0 auto;
    }
}