.page-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    font-size: 15px;
    a {
        color: #fff;
        &:hover {
            color: $color-primary;
        }
    }
    .logo {
        position: relative;
        top: 22px;
        left: 22px;
        display: inline-block;
        width: 258px;
        height: 48px;
    }
    .phone-field {
        position: relative;
        overflow: hidden;
        display: inline-block;
        height: 50px;
        line-height: 50px;
        margin-left: 20px;
        padding-left: 12px;
        font-size: 18px;
        text-transform: uppercase;
        p {
            padding: 0 40px 0 30px;
            background: #00a2ff;
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 6px;
                width: 12px;
                height: 52px;
                background: $color-primary;
                transform: rotate(12deg);
            }
        }
    }
    .page-menu {
        display: inline-block;
        float: right;
        .desktop-menu {
            float: right;
            display: inline-block;
            height: 50px; 
            text-align: right;
            transform: translate3d(0, 0, 0);
            transform-style: preserve-3d;
            ul {
                display: inline-block;
                vertical-align: top;
            }
            li {
                position: relative; 
                display: inline-block; 
                text-transform: uppercase;
                a {
                    display: block;
                    line-height: 50px;
                }
                & a:hover {
                }
                & + li {
                    margin-left: 20px;
                }
            }
        }
        .menu-img {
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
        }
        .mobile-header {
            float: left;
            .menu-button {
                display: block; 
                position: absolute; 
                top: 0px; 
                right: 0; 
                z-index: 3;
                width: 70px; 
                height: 70px; 
                padding: 13px;
                border: none;
                background: $color-primary;
                cursor: pointer;
                span {
                    display: block; 
                    width: 45px; 
                    height: 5px; 
                    margin-top: -2px; 
                    background: #fff; 
                    text-indent: -999em; 
                    transition: all 0.25s ease;
                    &:before,
                    &:after {
                        content: ''; 
                        position: absolute; 
                        top: 17px; 
                        display: block; 
                        width: 45px; 
                        height: 5px; 
                        margin-top: 15px; 
                        background: #fff; 
                        text-indent: -999em; 
                        transition: all 0.25s ease;
                    }
                    &:before {
                        transform: translateY(-14px) rotate(0deg);
                    }
                    &:after {
                        transform: translateY(14px) rotate(0deg);
                    }
                }
                &.open span {
                    background: $color-primary;
                    &:before {
                        transform: translateY(0px) rotate(45deg);
                    }
                    &:after {transform: translateY(0px) rotate(-45deg);
                    }
                }
                .button-closed {
                    display: block;
                }
                .button-open {
                    display: none;
                }
            }
            .mobile-menu {
                display: none;
                position: absolute;
                z-index: 2;
                top: 0px;
                left: 0;
                width: 100%;
                background: transparentize($color-primary, 0.05);
                padding-bottom: 20px;
                .logo {
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: auto;
                    max-width: 380px;
                    padding: 20px;
                    margin: 10vh auto;
                    margin-bottom: 80px;
                }
                li {
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    a {
                        font-size: 64px;
                        line-height: 64px;
                        img {
                            display: none
                        }
                        &:hover {
                            color: inherit;
                        }
                    }
                    & + li {
                        margin-top: 20px;
                    }
                }
                .phone-field {
                    width: 100%;
                    height: auto;
                    font-size: 64px;
                    line-height: 64px;
                    margin: 0;
                    margin-bottom: 140px;
                    text-align: center;
                    p {
                        background: none;
                        &:before {
                            position: relative;
                            left: 0;
                            display: block;
                            width: 152px;
                            height: 28px;
                            margin: 140px auto;
                            background: url('../img/img_bike_chain_white.png') no-repeat;
                            background-size: 100%;
                            transform: none;
                        }
                    }
                }
                @include gridle_state (sm xs) {
                    .logo {
                        margin-bottom: 10px;
                    }
                    li a, .phone-field {
                        font-size: 10vw;
                        line-height: 12vw;
                    }
                    .phone-field {
                        margin-bottom: 60px;
                        p {
                            &:before {
                                margin: 60px auto;
                            }
                        }
                    }
                }
            }
            @include gridle_state (lg xl) {
                display: none !important;
            }
        }
        &.open {
            .mobile-header {
                button {
                    .button-closed {
                        display: none;
                    }
                    .button-open {
                        display: block;
                    }
                }
            }
        }
    }
    @include gridle_state (md sm xs) {
        height: 70px;
        .page-menu {
            padding: 0 20px;
            .desktop-menu {
                display: none;
            }
        }
    }
    @include gridle_state (sm xs) {
        & > .logo {
            max-width: 210px;
        }
    }
}