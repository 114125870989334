/*----- BASE -----*/
input,
button,
select {vertical-align: middle; outline: none}
textarea {vertical-align: top; outline: none}
input[type="date"] {-webkit-appearance: none; -moz-appearance: textfield; appearance: textfield}

.ios input, .ios select, .ios textarea {-webkit-appearance: none}


/*----- POLA FORMULARZY -----*/
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="url"],
textarea {width: 100%; padding: 8px; background: #f3f3f3; border: 1px solid #e8e8e8; border-radius: 0; color: #6b6b6b; transition: border 0.25s ease}

/* Zdarzenia */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="date"]:focus,
input[type="url"]:focus,
.button:focus,
textarea:focus {border-color: $color-primary; outline: 0 none;}

button,
select,
label[for],
input[type="reset"],
input[type="radio"],
input[type="button"],
input[type="submit"],
input[type="checkbox"] {cursor: pointer}

button[disabled],
select[disabled],
label[disabled][for],
input[disabled][type="reset"],
input[disabled][type="radio"],
input[disabled][type="button"],
input[disabled][type="submit"],
input[disabled][type="checkbox"] {cursor: default}


/*----- PRZYCISKI -----*/
.button {
    position: relative; 
    display: inline-block; 
    overflow: hidden; 
    min-height: 42px; 
    padding: 0px 46px; 
    color: #000; 
    background: #fff; 
    border: 4px solid #000; 
    border-radius: 0; 
    font-family: $font-family;
    font-size: 18px; 
    font-weight: 700;
    line-height: 40px;
    text-align: center; 
    text-decoration: none; 
    text-transform: uppercase;
    transition: all 0.25s ease;
    &.active,
    &:hover {
        color: #fff;
        border-color: $color-primary;
        background: $color-primary;
    }
    a {
        color: #7d7e7e;
    }
    img {
        display: inline-block;
        vertical-align: middle;
        &.arrow {
            position: relative;
            top: -2px;
            margin-right: 10px;
        }
    }
    i {
        &.left {
            float: left; 
            padding-right: 10px;
        }
        &.right {
            float: right;
            padding-left: 10px;
        }
    }
    &.text-hide {
        padding-left: 0;
        padding-right: 0;
        text-indent: -999em;
    }
}

/* Grupa przycisków */
.button-group {
    .button {
        float: left;
        border-right-width: 0;
        border-radius: 0;
        &:first-child {
            border-radius: 5px 0 0 5px
        }
        &:last-child {
            border-right-width: 1px;
            border-radius: 0 5px 5px 0;
        }
    }
}

/*----- CHECKBOX -----*/
input[type="checkbox"] {
    position: absolute;
    visibility: hidden;
    & + span {
        overflow: hidden;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #dcdcdc;
        background: #fff;
        text-indent: -99em;
        vertical-align: middle;
        white-space: nowrap;
        cursor: pointer;
        &:before {
            float: left;
            width: 100%;
            height: 100%;
            text-indent: 0;
            color: transparent;
            font-size: 12px;
            text-align: center;
            line-height: 18px;
            transition: color 0.25s ease;
        }
    }
    &:checked {
        & + span:before {
            color: #222;
        }
    }
}

/*----- RADIO BUTTON -----*/
input[type="radio"] {
    position: absolute;
    visibility: hidden;
    & + span {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #dcdcdc;
        border-radius: 20px;
        background: #fff;
        text-indent: -99em;
        vertical-align: middle;
        white-space: nowrap;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 10px;
            height: 10px;
            margin: auto;
            border-radius: 10px;
            background: transparent;
            transition: background 0.25s ease;
        }
    }
    &:checked {
        & + span:before {
            background: #222;
        }
    }
}


/*----- SELECTBOX -----*/
