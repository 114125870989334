@font-face {
    font-family: 'slick';
    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'),
         url('../fonts/slick.woff') format('woff'),
         url('../fonts/slick.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/oswald-light-webfont.eot');
    src: url('../fonts/oswald-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald-light-webfont.woff') format('woff'),
         url('../fonts/oswald-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/oswald-regular-webfont.eot');
    src: url('../fonts/oswald-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald-regular-webfont.woff') format('woff'),
         url('../fonts/oswald-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../fonts/oswald-bold-webfont.eot');
    src: url('../fonts/oswald-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald-bold-webfont.woff') format('woff'),
         url('../fonts/oswald-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Roboto&subset=latin-ext');