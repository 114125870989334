// import gridle
@import "gridle";

// setting up the grid
@include gridle_setup( (
    context: 12,
    gutter-width: 20px,
    gutter-height: 20px
));

// register states
@include gridle_register_state(xl, (
  min-width: 1280px,
));
@include gridle_register_state(lg, (
  min-width: 960px,
  max-width: 1279px
));
@include gridle_register_state(md, (
  min-width: 720px,
  max-width: 959px,
  gutter-width: 12px,
  gutter-height: 12px
));
@include gridle_register_state(sm, (
  min-width: 480px,
  max-width: 719px,
  gutter-width: 12px,
  gutter-height: 12px
));
@include gridle_register_state(xs, (
  max-width: 479px,
  gutter-width: 12px,
  gutter-height: 12px
));

// even with full custom queries :
/*@include gridle_register_state (landscape, (
    query : "(orientation : landscape)"
));*/