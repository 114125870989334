/*
 * Style dla treści z edytorów WYSIWYG
 */
.wysiwyg {
    * + p,
    * + dl,
    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6,
    * + ol,
    * + ul,
    * + figure,
    * + table,
    * + blockquote {
        margin-top: 20px
    }
    
    ul {
        li {
            position: relative;
            padding-left: 40px;
            &:before {
                content: '♦';
                position: absolute;
                left: 0;
                width: 28px;
                padding: 4px 0;
                color: #222;
                text-align: center;
                line-height: 1
            }
            & + li {
                margin-top: 10px;
            }
        }
        
    }

    ol {
        li {
            counter-increment: ordered-list;
            &:before {
                content: counter(ordered-list);
                display: inline-block;
                width: 32px;
                margin-right: 8px;
                padding: 7px 0;
                background: #E8E2DA;
                color: #222;
                font-size: 14px;
                text-align: center;
            }
            & + li {
                margin-top: 10px;
            }
        }
    }
    
    li {
        ul, ol {
            margin-top: 0; 
            margin-bottom: 0
        }
    }
    
    dl {
        dt {
            font-style: italic;
        }
        dd {
            & + dt {
                margin-top: 10px;
            }
        }
    }
}
